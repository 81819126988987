import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// Types
import type { Profile } from '@core/types';

// Services
import { AskQuestionsService } from '@core/services/ask-questions/ask-questions.service';
import { LeadsService } from '@core/services/leads/leads.service';
import { AsaplyticsService } from '@core/helpers/asaplytics/asaplytics.service';

@UntilDestroy()
@Component({
  selector: 'ask-questions',
  templateUrl: './ask-questions.component.html',
  styleUrls: ['./ask-questions.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AskQuestionsComponent implements OnInit {

  @Input() user: Profile;

  @Input() mainText: string = 'Contact me to discuss your refinance options';

  @Input() screenName: string;

  @Input() preparedQuestions: string[];

  @Input() isWithAvatar: boolean = true;

  questionControl: UntypedFormControl = new UntypedFormControl('', Validators.maxLength(150));

  sendRequestAction$: Subscription;

  errorMessage: string = 'Maximum message length is 150 symbols';

  isQuestionSent: boolean = false;

  isFormExpanded: boolean = false;

  selectedQuestionIndex: number = null;

  leadUid: string;

  constructor(
    private questionsService: AskQuestionsService,
    private asaplyticsService: AsaplyticsService,
    private leadService: LeadsService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const { id } = this.leadService.getLeadValue();

    this.leadUid = id;
  }

  questionIsSending(index: number): boolean {
    return this.selectedQuestionIndex === index;
  }

  selectQuestion(questionIndex?: number): void {
    this.selectedQuestionIndex = questionIndex;
    this.sendQuestion(this.preparedQuestions[questionIndex]);
  }

  sendQuestion(value?: string): void {
    this.asaplyticsService.trackClickSendQuestionOnResult();

    this.sendRequestAction$ = this
      .questionsService
      .sendQuestion(this.leadUid, value, this.screenName)
      .pipe(
        delay(1000),
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.questionControl.setValue('');
          this.isQuestionSent = true;
          this.isFormExpanded = false;
          this.selectedQuestionIndex = null;
          this.cdr.detectChanges();
        },
      );
  }

}
