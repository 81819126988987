import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormError, provideRefs } from 'asap-team/asap-tools';

@UntilDestroy()
@Component({
  selector: 'iq-form-control-mask',
  templateUrl: './iq-form-control-mask.component.html',
  styleUrls: ['./iq-form-control-mask.component.sass'],
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  providers: provideRefs(IqFormControlMaskComponent),
})
export class IqFormControlMaskComponent implements ControlValueAccessor, OnInit {

  @ViewChild('nativeInput', { static: true, read: ElementRef }) nativeInput: ElementRef<HTMLInputElement>;

  @Input() autofocus: boolean;

  @Input() label: string | null = null;

  @Input() labelStyle: 'wide' | null = null;

  @Input() placeholder: string | null = null;

  @Input() mask: string = null;

  @Input() prefix: string = '';

  @Input() suffix: string = '';

  @Input() separatorLimit: string = null;

  @Input() thousandSeparator: string = '';

  @Input() icon: string;

  @Input() lpignore: boolean = true;

  @Input() errorsModel: FormError[] | string[];

  @Input() formControlName: string;

  focused: boolean = false;

  disabled: boolean = false;

  form: UntypedFormGroup;

  constructor(
    private renderer2: Renderer2,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({ value: [''] });

    this
      .form
      .controls
      .value
      .valueChanges
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((value: any) => {
        this.onChange(value);
      });
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(value: string): void {
    this.form.patchValue({ value });
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.renderer2.setProperty(this.nativeInput.nativeElement, 'disabled', isDisabled);
  }

  validate(): ValidationErrors | null {
    return null;
  }

  blur(): void {
    this.focused = false;
    this.onTouched();
  }

  getNativeElementRef(): ElementRef<HTMLButtonElement> {
    return this.nativeInput;
  }

}
